import { ComponentProps, ElementType, ReactNode } from 'react';

export type AccordionOwnProps<E extends ElementType = ElementType> = {
  triggerSlot: ReactNode;
  listSlot: ReactNode;
  isOpened: boolean;
  toggle: () => void;
  as?: E;
  listContainerClassname?: string;
};

export const defaultAccordionElement = 'section';

export type AccordionProps<E extends ElementType> = AccordionOwnProps<E> &
  Omit<ComponentProps<E>, keyof AccordionOwnProps>;
