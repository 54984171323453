import {
  ComponentProps,
  ElementType,
  ForwardedRef,
  MutableRefObject,
  ReactNode,
  RefObject,
} from 'react';
interface MutableRefProp<T> {
  mRef?: MutableRefObject<T> | RefObject<T> | ForwardedRef<T>;
}

export type TypographyOwnProps<E extends ElementType = ElementType> = {
  children: ReactNode;
  as?: E;
  size?: 'xs' | 's' | 'base' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
  decoration?: 'normal' | 'underline' | 'line-through';
  weight?: 'bold' | 'semi-bold' | 'medium' | 'regular';
};

export const defaultTextElement = 'p';

export type TypographyProps<E extends ElementType> = TypographyOwnProps<E> &
  Omit<ComponentProps<E>, keyof TypographyOwnProps> &
  MutableRefProp<any>;
