import cn from 'clsx';
import React from 'react';

import { Button, Illustration, Typography } from '@/shared/ui';

import styles from './modal-content.module.scss';
import {
  ContentBodyProps,
  ContentButtonProps,
  ContentCloseButtonProps,
  ContentFooterProps,
  ContentTitleProps,
  ModalContentProps,
} from './modal-content.types';

export const ModalContent = ({
  children,
  className,
  topRightSlot,
  ...props
}: ModalContentProps) => {
  return (
    <div className={cn(styles['container'], className)} {...props}>
      <div id={'modal-content-container'} className={styles['content']}>
        {topRightSlot && (
          <div className={styles['top-right-slot']}>{topRightSlot}</div>
        )}
        {children}
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
ModalContent.CloseButton = ({
  children,
  className,
  ...props
}: ContentCloseButtonProps) => {
  return (
    <Button
      className={cn(styles['close-button'], className)}
      variant="quaternary"
      width={'content'}
      iconLeft={
        <Illustration
          spriteName={'icons'}
          name={'x-close'}
          spanTagClassName={styles.cross}
        />
      }
      {...props}
    />
  );
};

// eslint-disable-next-line react/display-name
ModalContent.Button = ({
  children,
  className,
  ...props
}: ContentButtonProps) => {
  return (
    <Button
      className={cn(styles['button'], className)}
      width={'full-w'}
      {...props}
    />
  );
};

// eslint-disable-next-line react/display-name
ModalContent.Body = ({ children, className, ...props }: ContentBodyProps) => {
  return (
    <div className={cn(styles['body'], className)} {...props}>
      {children}
    </div>
  );
};

// eslint-disable-next-line react/display-name
ModalContent.Footer = ({
  children,
  className,
  height = 'content',
  ...props
}: ContentFooterProps) => {
  return (
    <div
      className={cn(
        styles['footer'],
        { [styles['full-h']]: height === 'full-h' },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

// eslint-disable-next-line react/display-name
ModalContent.Title = ({
  children,
  className,
  title,
  subtitle,
  ...props
}: ContentTitleProps) => {
  return (
    <div className={cn(styles['title-block'], className)} {...props}>
      {title && <Typography className={styles['title']}>{title}</Typography>}
      {subtitle && (
        <Typography className={styles['subtitle']}>{subtitle}</Typography>
      )}
    </div>
  );
};
