import cn from 'clsx';
import React, { ComponentProps, ReactNode } from 'react';
import Sheet from 'react-modal-sheet';
import { useMediaQuery } from 'usehooks-ts';
import { useIsClient } from 'usehooks-ts';

import { Button, Typography } from '@/shared/ui';
import { ModalDesktop } from '@/shared/ui/desktop/modal-desktop';

import styles from './bottom-sheet.module.scss';
import { BottomSheetProps } from './types';

export const BottomSheetUi = ({
  isOpen = false,
  children,
  handleClose,
  fullHeight,
  className,
}: BottomSheetProps) => {
  const isLargeMobile = useMediaQuery('(min-width: 744px)');
  const isClient = useIsClient();

  const modalClasses = cn(styles.modal, className);
  const sheetClasses = cn(styles.sheetContainer, className);

  if (!isClient) return null;

  return isLargeMobile ? (
    <ModalDesktop
      isOpened={isOpen}
      handleClose={handleClose}
      className={modalClasses}
    >
      {children}
    </ModalDesktop>
  ) : (
    <>
      {isOpen && <BottomSheetUi.Backdrop onClick={handleClose} />}
      <Sheet
        style={{ zIndex: 5000 }}
        isOpen={isOpen}
        onClose={handleClose}
        detent={fullHeight ? 'full-height' : 'content-height'}
      >
        <Sheet.Container
          style={{
            borderTopLeftRadius: fullHeight ? 0 : 16,
            borderTopRightRadius: fullHeight ? 0 : 16,
          }}
          className={sheetClasses}
        >
          <Sheet.Header>
            <div />
          </Sheet.Header>
          <Sheet.Content>{children}</Sheet.Content>
        </Sheet.Container>
      </Sheet>
    </>
  );
};

// eslint-disable-next-line react/display-name
BottomSheetUi.Backdrop = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      id={'backdrop'}
      style={{
        zIndex: 5000,
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        touchAction: 'none',
        overflow: 'hidden',
        border: 'medium',
        //pointerEvents: 'none',
        opacity: 1,
      }}
      onClick={onClick}
    />
  );
};
// eslint-disable-next-line react/display-name
BottomSheetUi.Content = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn(styles['sheet-content'], className)}>{children}</div>
  );
};

// eslint-disable-next-line react/display-name
BottomSheetUi.Header = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <Typography
      weight="semi-bold"
      className={cn(styles['sheet-title'], className)}
    >
      {children}
    </Typography>
  );
};

type CloseButton = ComponentProps<typeof Button>;

// eslint-disable-next-line react/display-name
BottomSheetUi.Button = ({ ...props }: CloseButton) => {
  return <Button className={styles.button} {...props} />;
};
