/*eslint-disable*/
import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import { OtherIcons as BuildIcons } from '@public/assets/build/icons/icons-interface';
import { hash as iconsHash } from '@public/assets/build/icons/currentHash';
import { hash as backgroundsHash } from '@public/assets/build/backgrounds/currentHash';
import { hash as countriesHash } from '@public/assets/build/countries/currentHash';
import { OtherIcons as BuildIllustrations } from '@public/assets/build/backgrounds/icons-interface';
import cn from 'clsx';
import styles from './illustration.module.scss';
import Image from 'next/image';

export type Icons = BuildIcons;
export type Illustrations = BuildIllustrations;

export type PaymentMethodIllustrations = {
  alfa: 'alfa';
  bitcoin: 'bitcoin';
  etherium: 'etherium';
  'game-money': 'game-money';
  payok: 'payok';
  qiwi: 'qiwi';
  tether: 'tether';
  tron: 'tron';
  'u-money': 'u-money';
  visa: 'visa';
  'web-pay': 'web-pay';
  'sber-pay': 'sber-pay';

  spei: 'spei';
  pix: 'pix';
  oxxo: 'oxxo';
  'nu-pay': 'nu-pay';
  'mexico-online-banking': 'mexico-online-banking';
  'mexico-local-stores': 'mexico-local-stores';
  codi: 'codi';
};

export type CountriesFlagsIllustrations = {
  'country-brazill': 'country-brazill';
  'country-france': 'country-france';
  'country-germany': 'country-germany';
  'country-italy': 'country-italy';
  'country-philippines': 'country-philippines';
  'country-portugal': 'country-portugal';
  'country-spain': 'country-spain';
  'country-turkey': 'country-turkey';
  'country-usa': 'country-usa';
};

type SpriteName = 'backgrounds' | 'icons' | 'countries';

export interface IllustrationProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  size?: number;
  width?: number | string;
  height?: number | string;
  children?: ReactNode;
  name: keyof Illustrations | keyof Icons | keyof CountriesFlagsIllustrations;
  useTagClassName?: string;
  spanTagClassName?: string;
  spriteName?: SpriteName;
  useSpriteAsSource?: boolean /* default - true*/ /* либа icon-pipeline оптимизирует иконки и
  кладет в соотв. папку в build и данный пропс (если useSpriteAsSource = false ) нужен для иконок у которых какой-то
  хитровыебаный clipPath (просто после оптимизациито что получилось в спрайте - это не то
  какой иконка была до преобразования в спрайт).
  Используя этот пропс source'ом иконки будет НЕ СПРАЙТ,
  а его build svg версия */;
}

export const Illustration = ({
  size,
  width,
  height,
  name,
  style,
  useTagClassName,
  spanTagClassName,
  spriteName = 'backgrounds',
  onClick,
  useSpriteAsSource = true,
  id,
}: IllustrationProps) => {
  const illustrationClasses = cn(styles.illustration, useTagClassName);
  const spanClasses = cn(
    'illustration-wrapper',
    styles.wrapper,
    spanTagClassName,
  );
  const customSize = {
    height: height ?? size,
    width: width ?? size,
    minWidth: width ?? size,
    minHeight: height ?? size,
    ...style,
  };

  const imageLoader = ({ src }: { src: string }) => {
    return `/assets/build/${spriteName}/${src}`;
  };

  const spriteHash = {
    icons: iconsHash,
    backgrounds: backgroundsHash,
    countries: countriesHash,
  } as const;

  if (!useSpriteAsSource) {
    return (
      <Image
        style={style}
        priority={true}
        className={spanClasses}
        loader={imageLoader}
        src={`${name}.svg`}
        width={Number(customSize.width)}
        height={Number(customSize.height)}
        quality={75}
        loading="eager"
        alt={`${name} flag item`}
      />
    );
  }

  return (
    <span id={id} style={customSize} className={spanClasses} onClick={onClick}>
      <svg>
        <use
          className={illustrationClasses}
          href={`/assets/build/${spriteName}/sprite-${spriteHash[spriteName]}.svg#${name}`}
        />
      </svg>
    </span>
  );
};
