import { Illustration } from '@/shared/ui';

import { ItemRarityLiveFeedIconSvgProps } from './item-rarity-live-feed.types';

export const colorsByVariant = {
  common: 'var(--rarity-common-background100)',
  uncommon: 'var(--rarity-uncommon-background100)',
  'ultra-rare': 'var(--rarity-ultra-rare-background100)',
  rare: 'var(--rarity-rare-background100)',
  legendary: 'var(--rarity-legendary-background100)',
} as const;

export const sizeItemRarityLiveFeed = {
  '120': 120,
  '100': 100,
  '78': 78,
  '72': 72,
  '58': 58,
  '52': 52,
} as const;

export const ItemRarityLiveFeed = ({
  variant = 'common',
  size,
  className,
  style,
  ...props
}: ItemRarityLiveFeedIconSvgProps) => {
  return (
    <Illustration
      spanTagClassName={className}
      style={{
        color: colorsByVariant[variant],
        width: sizeItemRarityLiveFeed[size],
        height: sizeItemRarityLiveFeed[size],
        ...style,
      }}
      name={'item-rarity-live-feed'}
    />
  );
};
