import { useCallback, useEffect, useState } from 'react';

export const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState<number>(0);

  const handleWindowResize = useCallback((event: any) => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  return { windowSize };
};
