import cn from 'clsx';

import { DividerProps } from '@/shared/ui/divider/divider.types';

import styles from './divider.module.scss';
export const Divider = ({ direction, className, ...props }: DividerProps) => {
  const dividerClasses = cn(
    styles.container,
    styles[`direction--${direction}`],
    className,
  );

  return <div className={dividerClasses} {...props}></div>;
};
