import cn from 'clsx';
import Image from 'next/image';

import { Badge } from '@/shared/ui';
import { AvatarProps } from '@/shared/ui/avatar/avatar.types';

import styles from './avatar.module.scss';

export const Avatar = ({
  className,
  isBordered = true,
  src, // какой-то фолбек должен быть
  hasNotifications = true,
  isMobile,
  ...props
}: AvatarProps) => {
  const avatarClasses = cn(
    styles.avatar,
    { [styles['avatar--bordered']]: isBordered },
    { [styles['avatar--mobile']]: isMobile },
    className,
  );

  return (
    <div className={avatarClasses} {...props}>
      {hasNotifications && (
        <Badge
          isMobile={isMobile}
          className={styles['avatar__img-container--badge']}
        />
      )}
      <div
        id={'avatar-img-container'}
        className={styles['avatar__img-container']}
      >
        <Image width={38} height={38} src={src} alt="avatar" />
      </div>
    </div>
  );
};
