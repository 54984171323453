import cn from 'clsx';
import { AnimatePresence } from 'framer-motion';
import { ElementType } from 'react';

import styles from './accordion.module.scss';
import { AccordionProps, defaultAccordionElement } from './accordion.types';

export const Accordion = <
  T extends ElementType = typeof defaultAccordionElement,
>({
    isOpened,
    className,
    toggle,
    triggerSlot,
    listSlot,
    as,
    listContainerClassname,
    ...props
  }: AccordionProps<T>) => {
  const accordionClasses = cn(styles['container'], className);
  //const listContainerClasses = cn(styles['list-container'], listContainerClassname);

  const TagName = as || defaultAccordionElement;

  return (
    <TagName onClick={toggle} className={accordionClasses} {...props}>
      {triggerSlot}

      <AnimatePresence initial={false}>{isOpened && listSlot}</AnimatePresence>
    </TagName>
  );
};
