import cn from 'clsx';
import React, { ElementType } from 'react';

import { Illustration } from '@/shared/ui';
import { Typography } from '@/shared/ui/typography';

import styles from './button.module.scss';
import { ButtonProps, defaultButtonElement } from './types';

export const Button = <T extends ElementType = typeof defaultButtonElement>({
  variant = 'primary',
  onClick,
  text,
  iconLeft,
  iconRight,
  width = 'full-w',
  className,
  disabled,
  loading,
  as,
  ...props
}: ButtonProps<T>) => {
  const buttonClasses = cn(
    styles.button,
    styles[variant],
    styles[`width--${width}`],
    { [styles.disabled]: disabled },
    className,
  );
  const TagName = as || defaultButtonElement;

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    onClick();
  };

  return (
    <TagName className={buttonClasses} onClick={handleClick} {...props}>
      {loading ? (
        <Illustration
          spriteName={'icons'}
          name={'loader'}
          spanTagClassName={styles.loader}
        />
      ) : (
        <>
          {Array.isArray(iconLeft) ? iconLeft.map(icon => icon) : iconLeft}
          {text && (
            <Typography
              as={'span'}
              id={'button-span-text'}
              weight="semi-bold"
              className={styles.text}
            >
              {text}
            </Typography>
          )}
          {Array.isArray(iconRight) ? iconRight.map(icon => icon) : iconRight}
        </>
      )}
    </TagName>
  );
};
