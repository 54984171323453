import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { PortalProps } from './portal.types';

export const Portal = ({ children, selector, ...props }: PortalProps) => {
  React.useEffect(() => setMounted(true), []);

  const ref = useRef<Element | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const element = document?.querySelector<HTMLElement>(selector);
    if (element) {
      ref.current = element;
      setMounted(true);
    }
  }, []);

  return mounted && ref.current ? createPortal(children, ref.current) : null;
};
