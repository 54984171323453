import cn from 'clsx';

import { Illustration } from '@/shared/ui';

import styles from './item-rarity-item-page.module.scss';
import { ItemRarityItemPageIconSvgProps } from './item-rarity-item-page.types';

export const colorsByVariant = {
  common: 'var(--rarity-common-background100)',
  uncommon: 'var(--rarity-uncommon-background100)',
  'ultra-rare': 'var(--rarity-ultra-rare-background100)',
  rare: 'var(--rarity-rare-background100)',
  legendary: 'var(--rarity-legendary-background100)',
} as const;

export const ItemRarityItemPage = ({
  variant,
  className,
  style,
  isDesktop,
  ...props
}: ItemRarityItemPageIconSvgProps) => {
  const itemRarityDesktopClasses = cn(styles['item-rarity'], className);
  const itemRarityMobileClasses = cn(styles['item-rarity-mobile'], className);

  return (
    <Illustration
      style={{ color: colorsByVariant[variant], ...style }}
      spanTagClassName={
        isDesktop ? itemRarityDesktopClasses : itemRarityMobileClasses
      }
      name={
        isDesktop ? 'item-rarity-item-page-1' : 'item-rarity-item-page-mobile-1'
      }
    />
  );
};
