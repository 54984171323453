import cn from 'clsx';

import { Typography } from '@/shared/ui';
import { BadgeProps } from '@/shared/ui/badge/badge.types';

import styles from './badge.module.scss';

export const Badge = ({
  bgColor = 'var(--cst-badge-background-default)',
  className,
  onClick,
  isMobile,
  style,
  count,
  ...props
}: BadgeProps) => {
  if (!count) {
    return;
  }

  const badgeClasses = cn(
    styles.badge,
    { [styles['one-number']]: count < 10 },
    { [styles['two-numbers']]: count >= 10 },
    { [styles['three-numbers']]: count > 99 },
    { [styles['badge--mobile']]: isMobile },
    className,
  );

  return (
    <div
      style={{ ...style, backgroundColor: bgColor }}
      className={badgeClasses}
      {...props}
    >
      {typeof count === 'number' && (
        <Typography as={'span'} className={styles['count']}>
          {count <= 0 ? 0 : count >= 100 ? '99+' : count}
        </Typography>
      )}
    </div>
  );
};
