import 'react-loading-skeleton/dist/skeleton.css';

import cn from 'clsx';
import SkeletonUi from 'react-loading-skeleton';

import { SkeletonProps } from '@/shared/ui/skeleton/skeleton.types';

import styles from './skeleton.module.scss';

export const Skeleton = ({ containerClassName, ...props }: SkeletonProps) => {
  const skeletonContainerClasses = cn(
    styles['skeleton-container'],
    containerClassName,
  );

  return (
    <SkeletonUi containerClassName={skeletonContainerClasses} {...props} />
  );
};
