import { useEffect, useState } from 'react';
import { useCountdown } from 'usehooks-ts';

export const sec2time = (timeInSeconds: number) => {
  const pad = (num: number, size: number): string => {
    return ('000' + num).slice(size * -1);
  };
  const time = parseFloat(timeInSeconds.toFixed(3));
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time - minutes * 60);

  return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
};
export const useTimer = ({
  duration,
  asCountdown = true,
}: {
  duration: number;
  asCountdown?: boolean;
}) => {
  const [intervalValue] = useState<number>(1000);

  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: duration,
      intervalMs: intervalValue,
      isIncrement: !asCountdown,
    });

  useEffect(() => {
    startCountdown();
  }, []);

  return {
    timer: sec2time(count),
    count,
    stopCountdown,
    resetCountdown,
    startCountdown,
  };
};
