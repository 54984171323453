import cn from 'clsx';

import { Illustration, Typography } from '@/shared/ui';

import styles from './circle-timer.module.scss';
import { CircleTimerProps } from './circle-timer.types';

export const CircleTimer = ({
  className,
  time,
  ...props
}: CircleTimerProps) => {
  return (
    <div className={styles['container']}>
      <div className={cn(styles['vectors-container'], className)} {...props}>
        <Illustration
          name={'circle-timer'}
          spriteName={'backgrounds'}
          spanTagClassName={styles['big-vector']}
        />
      </div>
      <Typography className={cn(styles['timer-text'], styles['center'])}>
        {time}
      </Typography>
    </div>
  );
};
