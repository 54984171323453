import { ComponentProps, ElementType, ReactNode } from 'react';

/*export type ButtonUiSize =
  | 'default'
  | 'm-xs'
  | 'm-s'
  | 'm-m'
  | 'm-l'
  | 'm-xl'
  | 'm-xxl'
  | 'xs'
  | 's'
  | 'm'
  | 'l'
  | 'xl'
  | 'xxl';*/

export type ButtonTypes = 'primary' | 'secondary' | 'tertiary' | 'quaternary';

export type ButtonOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  variant?: ButtonTypes;
  text?: string;
  /*size?: ButtonUiSize;*/
  iconLeft?: ReactNode | ReactNode[];
  iconRight?: ReactNode | ReactNode[];
  width?: 'content' | 'full-w';
  disabled?: boolean;
  loading?: boolean;
};
export const defaultButtonElement = 'button';
export type ButtonProps<E extends ElementType> = ButtonOwnProps<E> &
  Omit<ComponentProps<E>, keyof ButtonOwnProps>;
