import { Illustration, Typography } from '@/shared/ui';
import { EggCounterProps } from '@/shared/ui/egg-counter/egg-counter.types';

import styles from './egg-counter.module.scss';

export const EggCounter = ({ count, ...props }: EggCounterProps) => {
  return (
    <div className={styles['egg-counter']} {...props}>
      <Illustration
        id={'egg-counter--icon'}
        spanTagClassName={styles['egg-counter--icon']}
        spriteName={'icons'}
        name={'egg'}
      />
      <Typography className={styles['egg-counter--text']}>
        {count <= 0 ? 0 : count}
      </Typography>
    </div>
  );
};
