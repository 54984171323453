import cn from 'clsx';
import { ElementType } from 'react';

import styles from './typography.module.scss';
import { defaultTextElement, TypographyProps } from './typography.types';
export const Typography = <T extends ElementType = typeof defaultTextElement>({
  mRef,
  children,
  className = '',
  size,
  decoration = 'normal',
  weight = 'regular',
  as,
  ...props
}: TypographyProps<T>) => {
  const TagName = as || defaultTextElement;

  const typographyClasses = cn(
    styles.typography,
    { [styles[`size--${String(size)}`]]: size },
    styles[`weight--${weight}`],
    styles[`decoration--${decoration}`],
    className,
  );

  return (
    <TagName ref={mRef} className={typographyClasses} {...props}>
      {children}
    </TagName>
  );
};
