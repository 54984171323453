import { useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

type QueryStringProps = {
  pathname?: string;
  asMultipleParams?: boolean;
  resetBefore?: boolean;
  excludeFromReset?: string[];
  enableToggle?: boolean;
  name: string;
  value: string;
};

export const useQueryString = () => {
  const searchParams = useSearchParams();
  const urlSearchParams = new URLSearchParams(searchParams.toString());

  const mappedParams = new Map();

  for (const entry of urlSearchParams.entries()) {
    if (mappedParams.has(entry[0])) {
      const existedProp = mappedParams.get(entry[0]);
      existedProp !== entry[1] &&
        mappedParams.set(entry[0], [...existedProp, entry[1]]);
    } else {
      mappedParams.set(entry[0], [entry[1]]);
    }
  }

  const createQueryString = useCallback(
    ({
      pathname,
      value,
      name,
      asMultipleParams = true,
      enableToggle = false,
      resetBefore = false,
      excludeFromReset = [],
    }: QueryStringProps) => {
      let params = new URLSearchParams(searchParams.toString());

      if (resetBefore) {
        let newParams = new URLSearchParams('');

        if (excludeFromReset?.length > 0) {
          excludeFromReset?.map(itemToExclude => {
            params.getAll(itemToExclude).map(param => {
              newParams.append(itemToExclude, param);
            });
          });
          params = newParams;
        } else {
          params = new URLSearchParams('');
        }
      }

      if (params.has(name) && asMultipleParams) {
        !params.getAll(name).includes(value)
          ? params.append(name, value)
          : params.delete(name, value);
      } else {
        if (enableToggle) {
          !params.getAll(name).includes(value)
            ? params.set(name, value)
            : params.delete(name, value);
        } else {
          params.set(name, value);
        }
      }

      return pathname ? `${pathname}?${params.toString()}` : params.toString();
    },
    [searchParams],
  );

  return {
    createQueryString,
    urlSearchParams,
    mappedParams,
    params: Object.fromEntries(mappedParams),
  };
};
