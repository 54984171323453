import cn from 'clsx';

import { Illustration } from '@/shared/ui';

import styles from './item-rarity-card.module.scss';
import { ItemRarityCardIconSvgProps } from './item-rarity-card.types';

export const colorsByVariant = {
  common: 'var(--rarity-common-background100)',
  uncommon: 'var(--rarity-uncommon-background100)',
  'ultra-rare': 'var(--rarity-ultra-rare-background100)',
  rare: 'var(--rarity-rare-background100)',
  legendary: 'var(--rarity-legendary-background100)',
} as const;

export const sizeItemRarityCard = {
  '100': 100,
  '75': 75,
  '66': 66.67,
  '56': 56.67,
  '53': 53.33,
  '50': 50,
  '100%': '100%',
} as const;

export const ItemRarityCard = ({
  variant,
  size,
  className,
  style,
  ...props
}: ItemRarityCardIconSvgProps) => {
  const itemRarityCardClasses = cn(styles['item-rarity-card'], className);

  return (
    <Illustration
      style={{
        color: colorsByVariant[variant],
        width: sizeItemRarityCard[size],
        height: sizeItemRarityCard[size],
        ...style,
      }}
      spanTagClassName={itemRarityCardClasses}
      useTagClassName={itemRarityCardClasses}
      name={'item-rarity-card'}
    />
  );
};
