import cn from 'clsx';

import { Illustration } from '@/shared/ui';

import styles from './item-rarity-category-card.module.scss';
import { ItemRarityCategoryCardIconSvgProps } from './item-rarity-category-card.types';

export const colorsByVariant = {
  orange: '#FE9920',
  green: '#40BB18',
  black: '#212121',
  pink: '#D41057',
  blue: '#108ED4',
  purple: '#51459F',
} as const;

export const sizeItemRarityCategoryCard = {
  '178': 178,
  '130': 130,
} as const;

export const ItemRarityCategoryCard = ({
  variant,
  className,
  style,
  size,
  ...props
}: ItemRarityCategoryCardIconSvgProps) => {
  const itemRarityCardClasses = cn(
    styles['item-rarity-category-card'],
    className,
  );

  return (
    <Illustration
      style={{
        color: colorsByVariant[variant],
        width: sizeItemRarityCategoryCard[size],
        height: sizeItemRarityCategoryCard[size],
        ...style,
      }}
      spanTagClassName={itemRarityCardClasses}
      name={'item-rarity-category-card'}
    />
  );
};
