import cn from 'clsx';

import { Typography } from '@/shared/ui';
import { DescriptionProps } from '@/shared/ui/description/description.types';

import styles from './description.module.scss';

export const Description = ({ message, className }: DescriptionProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <Typography className={styles.text}>{message}</Typography>
    </div>
  );
};
