import { ItemProps, ItemVariant } from '@/shared/ui/item/item.types';

import { ItemImageByVariant } from './item-image-by-variant/item-image-by-variant';
export const Item = ({
  className,
  variant,
  size,
  ...props
}: ItemProps<ItemVariant>) => {
  return (
    <ItemImageByVariant<ItemVariant>
      {...props}
      className={className}
      variant={variant}
      size={size}
    />
  );
};
