import cn from 'clsx';

import { Illustration } from '@/shared/ui';

import styles from './tag-rarity.module.scss';
import { TagRarityProps, TagRarityVariants } from './tag-rarity.types';

const colorsByVariant = {
  common: '#4891FF',
  uncommon: '#7E10D4',
  rare: '#40BB18',
  'ultra-rare': '#E51A2E',
  legendary: '#303030',
  'tag-info': '#FDFDFD',
} as const;

export const TagRarity = ({
  size,
  variant,
  className,
  ...props
}: TagRarityProps<TagRarityVariants>) => {
  const tagRarityClasses = cn(
    styles['size'],
    styles[`size--${size}`],
    className,
  );

  return (
    <Illustration
      style={{
        color: colorsByVariant[variant as keyof typeof colorsByVariant],
      }}
      spanTagClassName={tagRarityClasses}
      spriteName={'icons'}
      name={'tag-rarity'}
    />
  );
};
