'use client';

import cn from 'clsx';
import { motion } from 'framer-motion';
import React from 'react';

import { Portal } from '@/shared/portal';

import styles from './modal-desktop.module.scss';
import { ContentProps, ModalProps } from './types';

const Content = ({ children, className, handleClose }: ContentProps) => {
  const animationConfig = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 1,
        type: 'spring',
      },
    },
    exit: {
      opacity: 0,
    },
  };

  const overlayClasses = cn(styles.overlay);

  const wrapperClasses = cn(styles.wrapper, className);

  return (
    <>
      <motion.div
        className={overlayClasses}
        {...animationConfig}
        onClick={handleClose}
      />
      <motion.div className={wrapperClasses} {...animationConfig}>
        {children}
      </motion.div>
    </>
  );
};

export const ModalDesktop: React.FC<ModalProps> = ({ children, ...props }) => {
  if (!props.isOpened) {
    return null;
  }

  return (
    <Portal selector={'#modal'}>
      <Content {...props}>{children}</Content>
    </Portal>
  );
};
