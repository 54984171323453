'use client';
import { useTimer } from '@hooks/client';
import cn from 'clsx';
import React, { useEffect, useMemo } from 'react';

import { Typography } from '@/shared/ui';
import { TagTimerProps } from '@/shared/ui/tag-timer/types';

import styles from './tag-timer.module.scss';

const min = 60;

export const TagTimer: React.FC<TagTimerProps> = ({
  duration = 3600,
  className,
  onTimesUp,
}) => {
  const { timer, count } = useTimer({
    duration,
  });

  const wrapperClasses = cn(
    styles['container'],
    styles['variant-danger'],
    className,
  );

  useEffect(() => {
    if (!count) {
      onTimesUp();
    }
  }, [count]);

  return (
    <div className={wrapperClasses}>
      <Typography className={styles['timer']}>{timer.substring(3)}</Typography>
    </div>
  );
};
